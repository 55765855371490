.button {
	font-weight: 700;
	box-shadow: $global-box-shadow;
	&.hollow {
		color: $black;
		&:hover {
			color: $black;
			background: $yellow-light;
		}
	}
}

section {
	li,
	p {
		a {
			text-decoration: none;
			color: $secondary-color;
			border-bottom: 1px solid $secondary-color;				
			&:hover {
				border-bottom: 1px solid $black;
				color: $black;
			}
		}
	}
}

.alert {
	p {
		color: $alert-color;
	}
}