.grid-container {
	position: relative;	
}

.page-title {
	display: inline-block;
	.index & {
		text-transform: uppercase;		
		&:after {
			content: "";
			display: block;
			height: 6px;
			width: 100%;
			margin: 10px 0 20px 2px;
			background: $black;
			@include breakpoint(medium down) { 		
				margin-bottom: 5px;
			}			
		}
		
	}
	
}

.section-title {
	display: inline-block;	
	&:before {
		content: "";
		display: block;
		height: 6px;
		width: 200px;
		max-width: 100%;
		margin: 10px 0 20px 2px;
		background: $black;

		
	}
	&.small {
		font-size: 30px;
	}	
	&.large {
		text-transform: uppercase;		
		font-size: 48px;	
	}
}

section {
	position: relative;
	padding: 100px 0 100px 0;
	ul {
		list-style-type: none;
		padding: 0;
		margin: 0 0 1rem 0;		
		@include breakpoint(medium down) { 	
			margin: 0;		
		}			
	}
	li {
		background: url('../images/circle.svg') no-repeat left 12px;
		padding: 0 0 0 1rem;		
	}
	.next {
		a {
			position: absolute;
			z-index: 100;
			top: -40px;
			width: 100%;
			height: 50px;
			display: block;
			cursor: pointer;			
		}
	}
}

.main-section {
	padding: 0 0 3rem 0;
	.grid-y.grid-frame {
		min-height: 90vh;
	}
	@include breakpoint(large) { 		
		.grid-y.grid-frame {
			min-height: 90vh;
			.contact & {
				min-height: 90vh;				
			}
		}
	}
	.index & {
		padding: 0;		
		@include breakpoint(large) { 				
			height: 95vh;
		}
	}	
	.page-title {
		padding-top: 250px;
		.contact & {
			padding-top: 150px;			
		}
		@include breakpoint(medium down) { 	
			padding-top: 130px;
		}	
		
	}
	
}

.sub-section {
	&.first {
		padding-top: 160px;		
	}	
	.medium-3 {
		text-align: right;
	}
}


.section-1 {
	background: transparent url(../images/section-1-bg.svg) 0 100% no-repeat;
	background-size: cover;
	.grid-frame {
		@include breakpoint(large) { 		
			background: transparent url(../images/bg-squares.svg) 50% 200px no-repeat;
			background-size: 90%;		
		}	
	}
}

.section-2 {
	background: $primary-color;
	box-shadow: $global-box-shadow;	
	&:before {
		content: "";
		display: block;
		position: relative;
		z-index: 9;
		width: 66px;
		height: 40px;
		background: $primary-color url(../images/down.svg) no-repeat 50% 50%;
		margin: auto;
		box-shadow: -2px -1px 2px 0px rgba(50, 50, 50, 0.2);
		top: -140px;
	}
	.default & {
		top: -60px;		
	}	
	
}

.section-4 {
	margin-top: -4rem;
	.index & {
		margin-top: 0;
		background: $light-gray;
		box-shadow: $global-box-shadow;	
	}
}


.contact-section {
	min-height: 100vh;	
}


.text-link {
	font-weight: 700;
	border-bottom: 1px solid $black;
	color: $black;
	&:hover {
		border-bottom: 1px solid $secondary-color;
		color: $secondary-color;
	}	
}



