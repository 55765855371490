header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 101;
	width: 100%;
	background: transparent;
	box-shadow: $global-box-shadow;
	height: 55px;
	transition: all 0.5s ease;
	.sticky-shrinknav-wrapper & {
		transition: all 0.5s ease;
		background: #fff;
	}
	.menu {
		justify-content: flex-end;
		margin-top: .3rem;		
	}
}

@include breakpoint(medium down) { 
	.grid-margin-x > .main-nav-wrapper {
		margin: 0;
	}
	.main-nav-wrapper {
		ul {
			display: block;
		}
		a {
			display: block;
			padding: 1rem;
		}
	}
}

.menu {
	li.last{
		a {
			padding-right: 0;			
			@include breakpoint(medium down) { 	
				padding-right: 1rem;			
			}				
		}
	}	
	a {
		color: $black;
		&:hover {
			text-decoration: underline;
		}
	}
}

.meta-menu {
	a {
		padding-right: 1rem;			
	}	
}

.payoff {
	position: absolute;
	right: .8rem;
	top: 80px;
	font-size: 24px;
	line-height: 1.3;
	font-family: $header-font-family;
	text-align: right;
	@include breakpoint(medium down) { 
		top: 68px;		
		max-width: 160px;
		font-size: 15px;
		
	}	
	.sticky-shrinknav-wrapper & {
		display: none;
	}
	span {
		font-family: $body-font-family;
		font-size: 20px;
		font-style: italic;
	}
}

.logo {
	width: 100%;			
	transition: all 0.5s ease;
	.sticky-shrinknav-wrapper &	{
		transition: all 0.3s ease;
		width: 57px;		
	}
	@include breakpoint(medium down) { 	
		width: 110px;
	}	
}

.close-menu-icon {
	position: absolute;
	z-index: 99;
	right: 0;
	top: 0;
	background: $black;
	color: $primary-color;
	padding: 1rem;
	font-family: $header-font-family;
}

